import * as React from "react"
import Header from "../components/layouts/Header"
import "../assets/css/global.scss";
import AboutBanner from "../assets/img/about-us-bg.svg";

import Banner from "../components/banner";
import Layouts from "../components/layouts";

import TabsPanel from "../components/tab/TabPanel";
import Tab from "../components/tab/Tab";
import Aos from "aos";
import "aos/dist/aos.css";

// markup
const IndexPage = () => {
    React.useEffect(function () {
        Aos.init({ duration: 500 });
    }, []);

    return (
        <>
            <Layouts>

                <Banner bannerVector={AboutBanner} >
                    <h2>About US</h2>
                    <p>What makes a company great? That’s simple.
                        It’s the people who work here.</p>
                </Banner>

                <div>
                    <main>
                        <div className="">
                            <div className="container page-section-top">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 py-3" data-aos="fade-right"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                        <div className="img-fluid text-center">
                                            <img className="about-team-colob" src="../img/team-colobration.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 py-3 pr-lg-5" data-aos="fade-left"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                        <h2 className="title-section">We're <span className="marked">Dynamic</span> Team of Creatives People</h2>
                                        <div className="divider" />
                                        <p>We provide marketing services to startups &amp; small business to looking for partner for their digital media, design &amp; dev lead generation &amp; communication.</p>
                                    </div>
                                </div>
                            </div> {/* .container */}
                        </div> {/* .page-section */}

                        <div className="">
                            <div className="container page-section">
                                <h2 className="about-us">Our Vision</h2>
                                <div class="divider mx-auto"></div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6 py-3 pr-lg-5" data-aos="fade-right"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                        <h2 className="title-section">We're <span className="marked">Dynamic</span> Team of Creatives People</h2>
                                        <div className="divider" />
                                        <p>We primarily invest in people alongside technological leverage to identify your individual business objectives. This helps us in crafting the most adapted and innovative solutions befitting the requirements while delivering business value. Through experience we firmly believe this humanistic outlook and approach creates difference in your business.</p>
                                    </div>
                                    <div className="col-lg-6 py-3" data-aos="fade-left"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                        <div className="img-fluid text-center">
                                            <img className="about-team-colob" src="../img/vision-img.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div> {/* .container */}
                        </div>

                        <div className="">
                            <div className="container page-section">
                                <h2 className="about-us">Our Mission</h2>
                                <div class="divider mx-auto"></div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6 py-3" data-aos="fade-right"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                        <div className="img-fluid text-center">
                                            <img className="about-team-colob mission" src="../img/mission.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 py-3 pr-lg-5" data-aos="fade-left"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                        <h2 className="title-section">We're <span className="marked">Dynamic</span> Team of Creatives People</h2>
                                        <div className="divider" />
                                        <p>While doing the right thing, we team up with you and strive to develop solutions customized specifically to meet your business goals and objectives.</p>
                                    </div>
                                </div>
                            </div> {/* .container */}
                        </div>

                        {/* <div className="article-row3">
                            <div className="container">
                                <h1 className="our-story-title animated wow fadeInUp">
                                    <img src="../img/about-us-img/our-story-icon.png" alt="ourstory" />
                                    <span>Our Story</span>
                                </h1>
                                <div className="row animated wow fadeInUp ourstory-responsive">
                                    <div className="our-story-card">
                                        <span className="osc-one animated flip">
                                            <img src="../img/about-us-img/ourstory-icon1.png" height={60} />
                                        </span>
                                        <span className="osc-two">
                                            5+
                                        </span>
                                        <span className="osc-three">
                                            Completing <br />Projects
                                        </span>
                                    </div>
                                    <div className="our-story-card">
                                        <span className="osc-one animated flip">
                                            <img src="../img/about-us-img/ourstory-icon2.png" height={60} />
                                        </span>
                                        <span className="osc-two">
                                            25+
                                        </span>
                                        <span className="osc-three">
                                            Digital <br />Solutions
                                        </span>
                                    </div>
                                    <div className="our-story-card">
                                        <span className="osc-one animated flip">
                                            <img src="../img/about-us-img/ourstory-icon3.png" height={60} />
                                        </span>
                                        <span className="osc-two">
                                            10+
                                        </span>
                                        <span className="osc-three">
                                            Agile Smart<br /> Employees
                                        </span>
                                    </div>
                                    <div className="our-story-card">
                                        <span className="osc-one animated flip">
                                            <img src="../img/about-us-img/ourstory-icon4.png" height={60} />
                                        </span>
                                        <span className="osc-two">
                                            20+
                                        </span>
                                        <span className="osc-three">
                                            Happy <br />Customers
                                        </span>
                                    </div>
                                    <div className="our-story-card">
                                        <span className="osc-one animated flip">
                                            <img src="../img/about-us-img/ourstory-icon5.png" height={60} />
                                        </span>
                                        <span className="osc-two">
                                            03+
                                        </span>
                                        <span className="osc-three">
                                            Global <br />Locations
                                        </span>
                                    </div>
                                </div>
                            </div>
    </div>*/}

                        <div className="tco-container-wrapper tco-container-wrapper--default  ">
                            <div className="tco-container tco-container--default  page-section">
                                <div className="tco-component">
                                    <div className="tco-card-grid-header tco-container--center tco-container--narrow">
                                        <h2 className="about-us ">Our Values</h2>
                                        <div class="divider mx-auto"></div>
                                        {/* <h2 className="tco-card-grid-heading tco-type-display--small">Our essential and enduring tenets.</h2>
                                        <div className="tco-card-grid-lede-container tco-container tco-container--narrow">
                                            <p className="tco-card-grid-lede tco-type-body--default">The guiding principles that we Thinkers are committed to as we work together with our coworkers and clients.</p>
                                        </div> */}
                                    </div>
                                    <div className="tco-card-grid tco-card-grid--3-column ">
                                        <div className="tco-card tco-card--summary" data-aos="fade-right"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                            <a href="" target className="tco-card-link">
                                                <div className="tco-card-image-container tco-card-image-container--summary">
                                                    <img alt="illustration of person holding a flag and cheering" data-src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-excellent.png" className="tco-card-image tco-card-image--summary ls-is-cached lazyloaded" src="../img/values-img1.svg" /><noscript>&lt;img class="tco-card-image tco-card-image--summary" alt="illustration of person holding a flag and cheering" src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-excellent.png" /&gt;</noscript>
                                                </div>
                                                <div className="tco-card-content-container">
                                                    <h2 className="tco-card-content-heading">Be <span className="box-heading">excellent.</span></h2>
                                                    <div class="divider"></div>
                                                    <p className="tco-card-content-description" /><div className="tco-info-card-info">
                                                        <p>We always aim for excellence – great people, great work, great client service, and great business practices.</p>
                                                    </div>
                                                    <p />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="tco-card tco-card--summary" data-aos="zoom-in"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                            <a href="" target className="tco-card-link">
                                                <div className="tco-card-image-container tco-card-image-container--summary">
                                                    <img alt="two people standing underneath an umbrella together" data-src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-kind-to-people.png" className="tco-card-image tco-card-image--summary ls-is-cached lazyloaded" src="../img/values-img2.svg" /><noscript>&lt;img class="tco-card-image tco-card-image--summary" alt="two people standing underneath an umbrella together" src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-kind-to-people.png" /&gt;</noscript>
                                                </div>
                                                <div className="tco-card-content-container">
                                                    <h2 className="tco-card-content-heading">Be <span className="box-heading">kind </span>to people.</h2>
                                                    <div class="divider "></div>
                                                    <p className="tco-card-content-description" /><div className="tco-info-card-info">
                                                        <p>We treat our coworkers and clients the way we’d like to be treated – with genuine care, kindness, and respect.</p>
                                                    </div>
                                                    <p />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="tco-card tco-card--summary" data-aos="fade-left"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                            <a href="" target className="tco-card-link">
                                                <div className="tco-card-image-container tco-card-image-container--summary">
                                                    <img alt="person in wheelchair raising their hand" data-src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-honest.png" className="tco-card-image tco-card-image--summary ls-is-cached lazyloaded" src="../img/values-img3.svg" /><noscript>&lt;img class="tco-card-image tco-card-image--summary" alt="person in wheelchair raising their hand" src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-honest.png" /&gt;</noscript>
                                                </div>
                                                <div className="tco-card-content-container">
                                                    <h2 className="tco-card-content-heading">Be <span className="box-heading">honest.</span></h2>
                                                    <div class="divider "></div>
                                                    <p className="tco-card-content-description" /><div className="tco-info-card-info">
                                                        <p>We communicate openly and honestly with our coworkers and clients – believing that this leads to healthy relationships and work we can be proud of.</p>
                                                    </div>
                                                    <p />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="tco-card tco-card--summary"  data-aos="fade-right"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                            <a href="" target className="tco-card-link">
                                                <div className="tco-card-image-container tco-card-image-container--summary">
                                                    <img alt="one person sharing a pen with another person holding paper" data-src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Work-together.png" className="tco-card-image tco-card-image--summary ls-is-cached lazyloaded" src="../img/values-img4.svg" /><noscript>&lt;img class="tco-card-image tco-card-image--summary" alt="one person sharing a pen with another person holding paper" src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Work-together.png" /&gt;</noscript>
                                                </div>
                                                <div className="tco-card-content-container">
                                                    <h2 className="tco-card-content-heading">Work <span className="box-heading">together.</span></h2>
                                                    <div class="divider"></div>
                                                    <p className="tco-card-content-description" /><div className="tco-info-card-info">
                                                        <p>We collaborate with our coworkers and clients – valuing each others’ ideas and believing that teamwork will produce the best results.</p>
                                                    </div>
                                                    <p />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="tco-card tco-card--summary"  data-aos="zoom-in"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                            <a href="" target className="tco-card-link">
                                                <div className="tco-card-image-container tco-card-image-container--summary">
                                                    <img alt="person running enthusiastically" data-src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Continuously-improve.png" className="tco-card-image tco-card-image--summary ls-is-cached lazyloaded five-card" src="../img/values-img5.svg" /><noscript>&lt;img class="tco-card-image tco-card-image--summary" alt="person running enthusiastically" src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Continuously-improve.png" /&gt;</noscript>
                                                </div>
                                                <div className="tco-card-content-container">
                                                    <h2 className="tco-card-content-heading"><span className="box-heading">Continuously</span> improve.</h2>
                                                    <div class="divider"></div>
                                                    <p className="tco-card-content-description" /><div className="tco-info-card-info">
                                                        <p>We aim to get better and better at what we do—improving our job skills, increasing our leadership abilities, and growing our business.</p>
                                                    </div>
                                                    <p />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="tco-card tco-card--summary"  data-aos="fade-right"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                            <a href="" target className="tco-card-link">
                                                <div className="tco-card-image-container tco-card-image-container--summary">
                                                    <img alt="person taking care of a growing tree" data-src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-of-service.png" className="tco-card-image tco-card-image--summary ls-is-cached lazyloaded" src="../img/values-img6.svg" /><noscript>&lt;img class="tco-card-image tco-card-image--summary" alt="person taking care of a growing tree" src="https://www.thinkcompany.com/wp-content/uploads/2021/10/Be-of-service.png" /&gt;</noscript>
                                                </div>
                                                <div className="tco-card-content-container">
                                                    <h2 className="tco-card-content-heading">Be of <span className="box-heading">service.</span></h2>
                                                    <div class="divider"></div>
                                                    <p className="tco-card-content-description" /><div className="tco-info-card-info">
                                                        <p>We give more than is expected of us – to our coworkers and clients, to people that need help, and toward the advancement of our profession.</p>
                                                    </div>
                                                    <p />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container page-section">
                            <h2 className="about-us ">Our Methodologies</h2>
                            <div class="divider mx-auto"></div>

                            <TabsPanel>
                                <Tab
                                    title="Agile"
                                    subtitle="Agile"
                                    icon="../img/agile-box.svg"
                                >
                                    <div className="container">
                                        {/* <h2 className="about-us">Agile</h2> */}
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/agile-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Built for Every<span className="marked"> Member</span>  of the Team to Plan</h2>
                                                <div className="divider" />
                                                <p>Agile is an iterative approach to project management and software development that helps teams deliver value to their customers faster and with fewer headaches.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    title="Scrum"
                                    subtitle="Scrum"
                                    icon="../img/scrum-box.svg"
                                >
                                    <div className="container">
                                        {/* <h2 className="about-us">Scrum</h2> */}
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/scrum-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Scrum by <span className="marked"> written </span> as an episodic story</h2>
                                                <div className="divider" />
                                                <p>Scrum is a lightweight framework that helps people, teams and organizations generate value through adaptive solutions for complex problems.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    title="Devops"
                                    subtitle="Devops"
                                    icon="../img/devops-box.svg"
                                >
                                    <div className="container">
                                        {/* <h2 className="about-us">Devops</h2> */}
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/Deveops-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Advanced<span className="marked"> AIOps</span>  are key to scaling DevOps practices</h2>
                                                <div className="divider" />
                                                <p>DevOps is the combination of cultural philosophies, practices, and tools that increases an organization's ability to deliver applications and services at high velocity</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    title="Lean"
                                    subtitle="Lean"
                                    icon="../img/lean-box.svg"
                                >
                                    <div className="container">
                                        {/* <h2 className="about-us">Lean</h2> */}
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/lean-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Lean, also<span className="marked"> known </span> as purple drank, sizzurp</h2>
                                                <div className="divider" />
                                                <p>Originating in Houston, Texas, it's typically served in a white Styrofoam cup.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    title="Prototype"
                                    subtitle="Prototype"
                                    icon="../img/prototype-box.svg"
                                >
                                    <div className="container">
                                        {/* <h2 className="about-us">Prototype</h2> */}
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/prototype-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">An original model <span className="marked">something</span>   is patterned </h2>
                                                <div className="divider" />
                                                <p>Prototype takes the complexity out of client-side web programming. Built to solve real-world problems, it adds useful extensions to the browser</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                            </TabsPanel>

                            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item col">
                                    <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#agile-box" role="tab" aria-controls="pills-home" aria-selected="true" onClick={handleTabChange}>
                                        <div className="Methodologies-c-img"> <img className="" src="../img/agile-box.svg" alt="" /></div>
                                        <div>Agile</div>
                                    </a>
                                </li>
                                <li className="nav-item col">
                                    <a className="nav-link " id="pills-home-tab" data-toggle="pill" href="#scrum-box" role="tab" aria-controls="pills-home" aria-selected="true">
                                        <div className="Methodologies-c-img"><img className="" src="../img/scrum-box.svg" alt="" /></div>
                                        <div>Scrum</div>
                                    </a>
                                </li>
                                <li className="nav-item col">
                                    <a className="nav-link " id="pills-home-tab" data-toggle="pill" href="#devops-box" role="tab" aria-controls="pills-home" aria-selected="true">
                                        <div className="Methodologies-c-img"><img className="" src="../img/devops-box.svg" alt="" /></div>
                                        <div>Devops</div>
                                    </a>
                                </li>
                                <li className="nav-item col">
                                    <a className="nav-link " id="pills-home-tab" data-toggle="pill" href="#lean-box" role="tab" aria-controls="pills-home" aria-selected="true">
                                        <div className="Methodologies-c-img"><img className="" src="../img/lean-box.svg" alt="" /></div>
                                        <div>Lean</div>
                                    </a>
                                </li>
                                <li className="nav-item col">
                                    <a className="nav-link " id="pills-home-tab" data-toggle="pill" href="#prototype-box" role="tab" aria-controls="pills-home" aria-selected="true">
                                        <div className="Methodologies-c-img"><img className="" src="../img/prototype-box.svg" alt="" /></div>
                                        <div>Prototype</div>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade " id="agile-box" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="container">
                                        <h2 className="about-us">Agile</h2>
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/agile-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Built for Every<span className="marked"> Member</span>  of the Team to Plan</h2>
                                                <div className="divider" />
                                                <p>Agile is an iterative approach to project management and software development that helps teams deliver value to their customers faster and with fewer headaches.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="scrum-box" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="container">
                                        <h2 className="about-us">Scrum</h2>
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/scrum-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Scrum by Example is<span className="marked"> written </span> as an episodic story</h2>
                                                <div className="divider" />
                                                <p>Scrum is a lightweight framework that helps people, teams and organizations generate value through adaptive solutions for complex problems.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="devops-box" role="tabpanel" aria-labelledby="pills-contact-tab">
                                    <div className="container">
                                        <h2 className="about-us">Devops</h2>
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/devops-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Deep cloud observability & advanced<span className="marked"> AIOps</span>  are key to scaling DevOps practices</h2>
                                                <div className="divider" />
                                                <p>DevOps is the combination of cultural philosophies, practices, and tools that increases an organization's ability to deliver applications and services at high velocity</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="lean-box" role="tabpanel" aria-labelledby="pills-contact-tab">
                                    <div className="container">
                                        <h2 className="about-us">Lean</h2>
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/lean-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">Lean, also<span className="marked"> known </span> as purple drank, sizzurp</h2>
                                                <div className="divider" />
                                                <p>Originating in Houston, Texas, it's typically served in a white Styrofoam cup.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="prototype-box" role="tabpanel" aria-labelledby="pills-contact-tab">
                                    <div className="container">
                                        <h2 className="about-us">Prototype</h2>
                                        <div class="divider mx-auto"></div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 py-3" >
                                                <div className="img-fluid text-center">
                                                    <img className="about-team-colob mission" src="../img/prototype-img.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-3 pr-lg-5">
                                                <h2 className="title-section">An original model on which<span className="marked">something</span>   is patterned </h2>
                                                <div className="divider" />
                                                <p>Prototype takes the complexity out of client-side web programming. Built to solve real-world problems, it adds useful extensions to the browser</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </main>
                </div>
            </Layouts>
        </>
    )
}

export default IndexPage