import React, { Component } from "react";

class TabsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected || 0
        };
    }
    renderTab(index) {
        this.setState({
            selected: index
        });
    }

    renderMenu() {
        const panels = this.props.children;
        return panels.map((elem, index) => {
            let selectedStyle = index === this.state.selected ? " active" : " ";
            let icon = elem.props.icon;
            let display = elem.props.hide;
            return display ? null : (
                <li
                    key={index}
                    className="nav-item col"
                    
                    onClick={() => this.renderTab(index)}
                >
                    <a className={"nav-link show" + selectedStyle} id="pills-home-tab" data-toggle="pill" href="#agile-box" role="tab" aria-controls="pills-home" aria-selected="true">
                        <div className="Methodologies-c-img">
                             <img className="" src={elem.props.icon} alt="" />
                             </div>
                        <div className="Methodologies-content">{elem.props.title}</div>
                    </a>
                </li>
            );
        });
    }

    getSubtitle() {
        const subtitle = this.props.children[this.state.selected].props.subtitle;
        if (!subtitle) return null;

        return <h2 className="about-us">{subtitle}</h2>;
    }

    render() {
        return (
            <div>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">{this.renderMenu()}</ul>
               
                    {this.getSubtitle()}
                    {this.props.children[this.state.selected]}
               
            </div>
        );
    }
}

export default TabsPanel;
